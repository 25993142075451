/** @jsx jsx */
import Helmet from 'react-helmet'
import { jsx } from 'theme-ui'
import { Flex } from 'theme-ui'
import { Box } from 'theme-ui'
import Layout from '../components/Layout'
import Link from '../components/Link'
import Button from '../components/Button'
import './styles.css'

function AboutPage() {
    return (
        <Layout>
            <Helmet>
                <title>关于FDA助手</title>
            </Helmet>
            <div
                sx={{
                    paddingTop: 9,
                    paddingBottom: 5,
                    paddingX: 5,
                }}
            >
                <h1
                    sx={{
                        margin: 0,
                        fontSize: 6,
                        fontWeight: 'normal',
                        textAlign: 'center',
                    }}
                >
                    关于FDA助手的介绍
                </h1>
                <div
                    className="app"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 5,
                        fontSize: 3,
                    }}
                >
                    <img src={'/icons/icon-192x192.png'} sx={{ margin: '10px auto 30px', width: '144px' }} />
                    <p>为了帮助广大出口美国企业又快又好获得FDA（美国食品药品监督管理局）的行政许可，我们（<Link href="https://ear-weee.cn" target="_blank">奥斯曼</Link>）在浸淫FDA注册/认证咨询业务十余年的经验基础上研发并推出了这款产品：<b>FDA助手</b>。</p>
                    <p>FDA助手旨在解决广大出口美国企业的两大痛点：</p>
                    <ul>
                        <li>看不懂英文，因此对于美国FDA官网内容看不明白，进而对如何进行企业注册和产品列名等不甚了解</li>
                        <li>走太多弯路。因为不懂，所以不得不求助第三方；又由于社会纷繁复杂，因此经常出现遇到不良机构、多花冤枉钱等问题，给企业发展造成不少困扰</li>
                    </ul>
                    <p>FDA助手的产品目标是：</p>
                    <ul>
                        <li sx={{ fontWeight: 'bold', color: '#f00' }}>企业做FDA注册/认证，用「FDA助手」就够了</li>
                    </ul>
                    <p>为什么有这个底气呢？这是因为：</p>
                    <ol>
                        <li><b>变不懂为明白</b>：我们精心将美国FDA官网相关内容全盘中文化，让广大出口企业对于如何做FDA注册可以看得明明白白<br /><Link href="/" sx={{ color: '#06f', textDecoration: 'underline' }}>立即查看</Link></li>
                        <li><b>变明白为方便</b>：我们潜心研发出全中文版FDA注册填报功能，让广大出口企业可以随时自助进行FDA注册操作，而不用再受第三方限制<br /><Link href="/register" sx={{ color: '#06f', textDecoration: 'underline' }}>开始注册</Link></li>
                    </ol>
                    <p>这里特别就「自助FDA注册填报功能」说明如下：</p>
                    <ol>
                        <li>根据FDA规定，非美国企业进行FDA注册时需要提供美国代理人（简称：美代）信息。因此，选择使用我们的自助FDA注册填报功能，意味着缺省使用我们的美代服务</li>
                        <li>为了鼓励大家使用，经集团董事会决议，<b sx={{ color: '#06f' }}>对于新注册客户，免除首年美代服务费</b>(激光辐射产品除外)。企业后续再用自助FDA注册填报功能时，按正常价格收取美代服务费</li>
                    </ol>
                    <p>我们的美代服务费明码实价，童叟无欺：</p>
                    <Flex>
                        {/* <Box p={2} sx={{ color: '#000', backgroundColor: '#efefef', padding: '5px 10px' }}>
                            2021年收费标准
                        </Box> */}
                        <Box p={2} sx={{ flex: 'auto', color: '#fff', backgroundColor: '#444', padding: '5px 10px', textAlign: 'center' }}>
                            医疗器械、化妆品
                        </Box>
                        <Box p={2} sx={{ flex: 'auto', color: '#fff', backgroundColor: '#06f', padding: '5px 10px', textAlign: 'center' }}>
                            人民币￥3,000元/年
                        </Box>
                        <Box p={2} sx={{ flex: 'auto', color: '#fff', backgroundColor: '#444', padding: '5px 10px', textAlign: 'center' }}>
                            食品
                        </Box>
                        <Box p={2} sx={{ flex: 'auto', color: '#fff', backgroundColor: '#06f', padding: '5px 10px', textAlign: 'center' }}>
                            人民币￥3,000元/两年
                        </Box>
                    </Flex>
                    <i sx={{ marginTop: 5, fontSize: 1 }}>请注意：此价格每年初会根据市场行情进行调整；激光辐射产品收费标准请向客服咨询。</i>
                    <p>以上即关于FDA助手介绍的全部内容。有任何未尽事宜，可以联系奥斯曼400客服电话(<Link href="tel:400-686-5670" sx={{ color: '#06f', textDecoration: 'underline' }}>400 - 686 - 5670</Link>)获取解答。</p>
                    <Button
                        as="a"
                        href="/"
                        sx={{ display: 'block', width: '50%', variant: 'buttons.outline', marginTop: 10, marginX: 'auto' }}
                    >
                        返回首页
                    </Button>
                </div>
            </div>
        </Layout>
    )
}

export default AboutPage
